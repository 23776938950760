import { RoleEnum } from "./roles";

export const RouterEnum = Object.freeze({
  Analysis: {
    Path: "/analysis",
    Roles: [
      RoleEnum.Administrator,
      RoleEnum.SystemAdministrator,
      RoleEnum.Operator,
      RoleEnum.AntiMoneyLaudering,
      RoleEnum.Accountant,
      RoleEnum.SuperAdministrator,
      RoleEnum.Endorser,
      RoleEnum.EBUpload,
    ],
  },
  Workplace: {
    Path: "/workplace",
    Roles: [
      RoleEnum.Administrator,
      RoleEnum.SystemAdministrator,
      RoleEnum.Operator,
      RoleEnum.AntiMoneyLaudering,
      RoleEnum.Accountant,
      RoleEnum.SuperAdministrator,
      RoleEnum.Endorser,
      RoleEnum.EBUpload,
    ],
  },
  ReturnedUnpairedPayments: {
    Path: "/workplace/returned-unpaired-payments",
    Roles: [RoleEnum.Administrator],
  },
  UnpairedIncomingPayments: {
    Path: "/workplace/unpaired-payments",
    Roles: [RoleEnum.Administrator],
  },
  Monitor: {
    Path: "/monitor",
    Roles: [
      RoleEnum.Administrator,
      RoleEnum.SystemAdministrator,
      RoleEnum.Operator,
      RoleEnum.AntiMoneyLaudering,
      RoleEnum.Accountant,
      RoleEnum.SuperAdministrator,
      RoleEnum.Endorser,
      RoleEnum.EBUpload,
    ],
  },
  IncomingPayments: {
    Path: "/incoming-payments",
    Roles: [RoleEnum.Administrator],
  },
  IncomingPaymentDetail: {
    Path: "/incoming-payments/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/incoming-payments/${id}`,
  },
  OutgoingPayments: {
    Path: "/outgoing-payments",
    Roles: [RoleEnum.Administrator],
  },
  OutgoingPaymentDetail: {
    Path: "/outgoing-payments/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/outgoing-payments/${id}`,
  },
  RefundPayments: {
    Path: "/refund-payments",
    Roles: [RoleEnum.Administrator],
  },
  RefundPaymentsDetail: {
    Path: "/refund-payments/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/refund-payments/${id}`,
  },
  VirtualPayments: {
    Path: "/virtual-payments",
    Roles: [RoleEnum.Administrator],
  },
  Pos: {
    Path: "/pos",
    Roles: [RoleEnum.Administrator],
  },
  PosDetail: {
    Path: "/pos/transaction/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/pos/transaction/${id}`,
  },
  TransformStatement: {
    Path: "/statements/transform",
    Roles: [RoleEnum.Administrator],
  },
  UploadStatement: {
    Path: "/statements/upload",
    Roles: [RoleEnum.StatementUploader, RoleEnum.StatementProcessTrigger],
  },
  FDParser: {
    Path: "/statements/fd-parser",
    Roles: [RoleEnum.Administrator],
  },
  Currency: {
    Path: "/system/currency",
    Roles: [RoleEnum.SystemAdministrator],
  },
  Country: {
    Path: "/system/country",
    Roles: [RoleEnum.SystemAdministrator],
  },
  CountryAdd: {
    Path: "/system/country/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  CountryDetail: {
    Path: "/system/country/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/country/${id}`,
  },
  City: {
    Path: "/system/city",
    Roles: [RoleEnum.SystemAdministrator],
  },
  CityAdd: {
    Path: "/system/city/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  CityDetail: {
    Path: "/system/city/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/city/${id}`,
  },
  Region: {
    Path: "/system/region",
    Roles: [RoleEnum.SystemAdministrator],
  },
  RegionAdd: {
    Path: "/system/region/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  RegionDetail: {
    Path: "/system/region/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/region/${id}`,
  },
  Bank: {
    Path: "/system/bank",
    Roles: [RoleEnum.SystemAdministrator],
  },
  BankAdd: {
    Path: "/system/bank/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  Clients: {
    Path: "/clients",
    Roles: [RoleEnum.Administrator],
  },
  ClientDetail: {
    Path: "/clients/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/clients/${id}`,
  },
  Audit: {
    Path: "/reports/audit",
    Roles: [RoleEnum.Administrator],
  },
  AmlReports: {
    Path: "/reports/report-aml",
    Roles: [RoleEnum.Administrator],
  },
  AuditAnnualFees: {
    Path: "/reports/audit-annual-fees",
    Roles: [RoleEnum.Administrator],
  },
  PaymentAnalysis: {
    Path: "/reports/payment-analysis",
    Roles: [RoleEnum.Administrator],
  },
  Accounting: {
    Path: "/reports/accounting",
    Roles: [RoleEnum.Administrator],
  },
  PosTransactionReports: {
    Path: "/reports/pos-transaction-report",
    Roles: [RoleEnum.Administrator],
  },
  RefundReports: {
    Path: "/reports/refund",
    Roles: [RoleEnum.Administrator],
  },
  Registrations: {
    Path: "/registrations",
    Roles: [RoleEnum.Administrator],
  },
  RegistrationDetail: {
    Path: "/registrations/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/registrations/${id}`,
  },
  Governors: {
    Path: "/governors",
    Roles: [RoleEnum.Administrator],
  },
  GovernorDetail: {
    Path: "/governors/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/governors/${id}`,
  },
  GovernorAdd: {
    Path: "/governors/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  Partners: {
    Path: "/partners",
    Roles: [RoleEnum.Administrator],
  },
  PartnerDetail: {
    Path: "/partners/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/partners/${id}`,
  },
  PartnerAdd: {
    Path: "/partners/add",
    Roles: [RoleEnum.Administrator],
  },
  Extensions: {
    Path: "/extensions",
    Roles: [RoleEnum.Administrator],
  },
  Login: {
    Path: "/login",
  },
  Invoices: {
    Path: "/invoices",
    Roles: [RoleEnum.Administrator],
  },
  PohodaInvoices: {
    Path: "/pohoda-invoices",
    Roles: [RoleEnum.Administrator],
  },
  GenerateTestInvoice: {
    Path: "/invoices/test",
    Roles: [RoleEnum.Administrator],
  },
  UserCareType: {
    Path: "/system/user-care-type",
    Roles: [RoleEnum.SystemAdministrator],
  },
  UserCareTypeAdd: {
    Path: "/system/user-care-type/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  UserCareTypeDetail: {
    Path: "/system/user-care-type/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/user-care-type/${id}`,
  },
  UserCategoryType: {
    Path: "/system/user-category-type",
    Roles: [RoleEnum.SystemAdministrator],
  },
  UserCategoryTypeAdd: {
    Path: "/system/user-category-type/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  UserCategoryTypeDetail: {
    Path: "/system/user-category-type/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/user-category-type/${id}`,
  },
  ServiceSettings: {
    Gate: {
      Path: "/system/gate-service-settings",
      Roles: [RoleEnum.SystemAdministrator],
    },
  },
  PaymentIntents: {
    Path: "/payment-intents",
    Roles: [RoleEnum.Administrator],
  },
  PaymentIntentDetail: {
    Path: "/payment-intents/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/payment-intents/${id}`,
  },
  RecurringPaymentIntents: {
    Path: "/recurring-payment-intents",
    Roles: [RoleEnum.Administrator],
  },
  RecurringPaymentIntentDetail: {
    Path: "/recurring-payment-intents/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/recurring-payment-intents/${id}`,
  },
  MerchantCategoryCode: {
    Path: "/system/merchant-category-code",
    Roles: [RoleEnum.SystemAdministrator],
  },
  MerchantCategoryCodeAdd: {
    Path: "/system/merchant-category-code/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  MerchantCategoryCodeDetail: {
    Path: "/system/merchant-category-code/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/merchant-category-code/${id}`,
  },
  RiskLevel: {
    Path: "/system/risk-level",
    Roles: [RoleEnum.SystemAdministrator],
  },
  RiskLevelAdd: {
    Path: "/system/risk-level/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  RiskLevelDetail: {
    Path: "/system/risk-level/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/risk-level/${id}`,
  },
  PayUFirmType: {
    Path: "/system/payu-firm-type",
    Roles: [RoleEnum.SystemAdministrator],
  },
  PayUFirmTypeAdd: {
    Path: "/system/payu-firm-type/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  PayUFirmTypeDetail: {
    Path: "/system/payu-firm-type/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/payu-firm-type/${id}`,
  },
  RepresentativeType: {
    Path: "/system/representative-type",
    Roles: [RoleEnum.SystemAdministrator],
  },
  RepresentativeTypeAdd: {
    Path: "/system/representative-type/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  RepresentativeTypeDetail: {
    Path: "/system/representative-type/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/representative-type/${id}`,
  },
  PassiveService: {
    Path: "/system/passive-service",
    Roles: [RoleEnum.SystemAdministrator],
  },
  FallbackProcessor: {
    Path: "/system/fallback-processor",
    Roles: [RoleEnum.FallbackAdministrator],
  },
  FallbackProcessorAdd: {
    Path: "/system/fallback/add",
    Roles: [RoleEnum.FallbackAdministrator],
  },
  FallbackProcessorDetail: {
    Path: "/system/fallback/:id",
    Roles: [RoleEnum.FallbackAdministrator],
    generatePath: (id) => `/system/fallback/${id}`,
  },
  PayUAccount: {
    Path: "/system/payu-account",
    Roles: [RoleEnum.SystemAdministrator],
  },
  PayUAccountAdd: {
    Path: "/system/payu-account/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  PayUAccountDetail: {
    Path: "/system/payu-account/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/payu-account/${id}`,
  },
  TatraPremium: {
    Path: "/system/tatra-premium",
    Roles: [RoleEnum.TatraPremium],
  },
  TatraPremiumOatuhRedirect: {
    Path: "/system/tatra-premium/oauth-redirect",
    Roles: [RoleEnum.TatraPremium],
  },
  Accounts: {
    Path: "/accounts",
    Roles: [RoleEnum.Administrator],
  },
  AccountDetail: {
    Path: "/accounts/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/accounts/${id}`,
  },
  Company: {
    Path: "/company",
    Roles: [RoleEnum.Administrator],
  },
  CompanyAdd: {
    Path: "/system/company/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  CompanyDetail: {
    Path: "/company/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/company/${id}`,
  },
  Shop: {
    Path: "/shops",
    Roles: [RoleEnum.Administrator],
  },
  ShopDetail: {
    Path: "/shops/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/shops/${id}`,
  },
  Terminal: {
    Path: "/system/terminal",
    Roles: [RoleEnum.SystemAdministrator],
  },
  TerminalAdd: {
    Path: "/system/terminal/add",
    Roles: [RoleEnum.SystemAdministrator],
  },
  TerminalDetail: {
    Path: "/system/terminal/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/terminal/${id}`,
  },
  SharedBank: {
    Path: "/system/shared/bank",
    Roles: [RoleEnum.SystemAdministrator],
  },
  //TODO: vfrolo - create/edit is not allowed yet
  // SharedBankAdd: {
  //   Path: "/system/shared/bank/add",
  //   Roles: [RoleEnum.SystemAdministrator],
  // },
  SharedBankDetail: {
    Path: "/system/shared/bank/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/shared/bank/${id}`,
  },
  SharedLanguage: {
    Path: "/system/shared/language",
    Roles: [RoleEnum.SystemAdministrator],
  },
  SharedCurrency: {
    Path: "/system/shared/currency",
    Roles: [RoleEnum.SystemAdministrator],
  },
  SharedPaymentMethod: {
    Path: "/system/shared/payment-method",
    Roles: [RoleEnum.SystemAdministrator],
  },
  SharedPaymentMethodDetail: {
    Path: "/system/shared/payment-method/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/shared/payment-method/${id}`,
  },
  SharedBesteronBankAccount: {
    Path: "/system/shared/besteron-bank-account",
    Roles: [RoleEnum.SystemAdministrator],
  },
  SharedCountry: {
    Path: "/system/shared/country",
    Roles: [RoleEnum.SystemAdministrator],
  },
  SharedCountryDetail: {
    Path: "/system/shared/country/:id",
    Roles: [RoleEnum.SystemAdministrator],
    generatePath: (id) => `/system/shared/country/${id}`,
  },
  SharedHistory: {
    Path: "/system/shared/history",
    Roles: [RoleEnum.SystemAdministrator],
  },
  Forbidden: {
    Path: "/forbidden",
  },
  OnDemandPaymentIntents: {
    Path: "/on-demand-payment-intents",
    Roles: [RoleEnum.Administrator],
  },
  OnDemandPaymentIntentDetail: {
    Path: "/on-demand-payment-intents/:id",
    Roles: [RoleEnum.Administrator],
    generatePath: (id) => `/on-demand-payment-intents/${id}`,
  },
  PosUnprocessedTransactions: {
    Path: "/pos/unprocessed-transaction",
    Roles: [RoleEnum.PosUnprocessedTransactions],
  },
  Download: {
    Path: "/download",
    Roles: [RoleEnum.Administrator],
  },
  PaymentOrderFiles: {
    Path: "/payment-orders/files",
    Roles: [RoleEnum.DownloadPaymentOrderFile]
  }
});
