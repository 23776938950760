export const RoleEnum = Object.freeze({
  Administrator: "Administrator",
  SystemAdministrator: "System administrator",
  Operator: "Operator",
  AntiMoneyLaudering: "Anti money laudering",
  Accountant: "Accountant",
  SuperAdministrator: "Super administrator",
  Endorser: "Endorser",
  EBUpload: "EB upload",
  FallbackAdministrator: "Fallback administrator",
  StatementProcessTrigger: "Statement Process Trigger",
  StatementUploader: "Statement Uploader",
  TatraPremium: "Tatra Premium",
  ConfirmRefundWithoutSending: "Confirm Refund Without Sending",
  UnmatchedTatraStatements: "Unmatched Tatra Statements",
  PosUnprocessedTransactions: "POS Unprocessed Transactions",
  ReturnEndUserTransaction: "Return end user transaction",
  DownloadPaymentOrderFile: "Download payment order file"
});

export const RoleIntersection = (userRoles, requiredRoles) => {
  var roleIntersection = userRoles.filter((r) => requiredRoles.includes(r));

  return roleIntersection.length > 0;
};
